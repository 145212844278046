import { ChangeEvent, FunctionComponent, KeyboardEvent, MouseEvent, useState } from 'react'
import { css } from '@emotion/css'
import { Button, Grid, SvgIcon, TextField } from '@/tbui'

const promoCodeGrid = css`
  display: flex;
  gap: 12px;
`

const promoCodeChipList = css`
  display: flex;
  gap: 16px;
  flex-direction: row;
  flex-wrap: wrap;
`

const promoCodeChip = css`
  display: flex;
  align-items: center;
  gap: 16px;
  color: #454545;
  font-size: 16px;
  background-color: #f3f7f8;
  padding: 16px;
  border-radius: 8px;
`

interface PromoCodeProps {
  addPromoCodeHandler: (promoCode: string) => void
  removePromoCodeHandler: (promoCode: string) => void
  labels: string[]
}

const PromoCode: FunctionComponent<PromoCodeProps> = ({
  addPromoCodeHandler,
  removePromoCodeHandler,
  labels,
}) => {
  const [promoCodeTextInput, setPromoCodeTextInput] = useState<string>('')

  const updatePromoCodeTextInput = (e: ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault()
    const { value } = e.target
    setPromoCodeTextInput(value == null ? '' : value)
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>): void => {
    e.preventDefault()
    if (e.key === 'Enter') {
      handleAddPromoCode(promoCodeTextInput)
    }
  }

  const handleAddPromoCodeButtonClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    handleAddPromoCode(promoCodeTextInput)
  }

  const handleAddPromoCode = (label: string): void => {
    addPromoCodeHandler(label)
    setPromoCodeTextInput('')
  }

  return (
    <Grid container gap="16px" data-test="promo-code">
      <Grid className={promoCodeGrid}>
        <TextField
          style={{ height: '44px', margin: 0 }}
          variant="INPUT"
          height={16}
          value={promoCodeTextInput}
          onChange={updatePromoCodeTextInput}
          onKeyUp={handleKeyPress}
        />
        <Button variant="SECONDARY" onClick={handleAddPromoCodeButtonClick}>
          Add
        </Button>
      </Grid>
      <Grid className={promoCodeChipList}>
        {labels.map((label, index) => {
          return (
            <div key={index} className={promoCodeChip}>
              <SvgIcon
                name="close"
                fontSize="21"
                color="ERROR"
                onClick={() => removePromoCodeHandler(label)}
              />
              {label}
            </div>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default PromoCode
